body {
	color: #555 !important;
	font-family: 'arial' !important;
	line-height: 22px !important;
	background-color: #fff !important;
}
img {
	max-width:100%;
	height:auto
}
.fix {overflow:hidden}
p {
	margin:0px;
	color: #555;
}
h1, h2, h3, h4, h5, h6 {
	color: #333;
	margin-bottom: 10px;
	margin-top: 0px;
    font-weight: 400;
}
input:focus,select:focus{
	outline:none;
}
input, button{
    transition:all .3s;
    -webkit-transition:all .3s;
    -moz-transition:all .3s;
}
a {
	transition: all 0.3s ease 0s;
    -webkit-transition:all .3s;
    -moz-transition:all .3s;
	text-decoration:none;
	color:#333;
	font-size:13px;
}
a:hover{
	color:#296dc1;
}
a:active, a:hover, a:focus {
	outline: none;
	text-decoration:none;
}
button:focus{
	outline:none;
}
ul{
	list-style: outside none none;
	margin: 0;
	padding: 0;
}
.bg-1{background:#fafafa}
.bg-2{background:#296dc1;}
.bg-fff{background:#fff}
/* -- Show More Start --*/
.showMore {
	text-align: center;
	margin-top: 15px;
}
.atag_btn,
.showMore button {
	display: inline-block;
	text-decoration: none;
	font-size: 14px;
	line-height: 20px;
	position: relative;
	border: 1px solid #296dc1;
	background-color: transparent;
	color: #296dc1;
	padding: 5px 15px;
	font-weight: 600;
	border-radius: 4px;
}
.atag_btn:after,
.showMore button:after {
	content: "\f105";
	font-family: 'Font Awesome 5 Free';
	position: absolute;
	left: 0;
	top: 23px;
	font-weight: 900;
	border: 1px solid #ef512c;
	right: 0;
	margin: 0 auto;
	width: 16px;
	height: 16px;
	background-color: #fff;
	color: #ef512c;
	border-radius: 50%;
	font-size: 12px;
	line-height: 10px;
	transform: rotate(90deg);
	text-align: center;
	padding: 2px 4px;
}
.atag_btn:hover,
.showMore button:hover {
	border: 1px solid #296dc1;
	padding: 5px 15px;
	background-color: #296dc1;
	color: #fff;
}
.atag_btn:hover:after,
.showMore button:hover:after {
	background-color: #ef512c;
	color: #fff;
}
.showMore.showMoreBtn button,
.showMore.showMoreBtn button:hover {
	display: inline-block;
	text-decoration: none;
	font-size: 14px;
	line-height: 20px;
	position: relative;
	border: 1px solid #ef512c;
	background-color: transparent;
	color: #ef512c;
	padding: 5px 15px;
	font-weight: 600;
	border-radius: 4px;
}
.showMore.showMoreBtn button:after {
	content: "\f105";
	font-family: 'Font Awesome 5 Free';
	position: absolute;
	left: 0;
	top: 23px;
	font-weight: 900;
	border: 1px solid #296dc1;
	right: 0;
	margin: 0 auto;
	width: 16px;
	height: 16px;
	background-color: #fff;
	color: #296dc1;
	border-radius: 50%;
	font-size: 12px;
	line-height: 10px;
	transform: rotate(90deg);
	text-align: center;
	padding: 2px 4px;
}
.showMore.showMoreBtn button:hover {
	/* border: 1px solid #ef512c; */
	padding: 5px 15px;
	background-color: #ef512c;
	color: #fff;
}
.showMore.showMoreBtn button:hover:after {
	background-color: #296dc1;
	color: #fff;
}
.showMore button i {
	margin-left: 5px;
}
.headerScrollSticky {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
	background: #fff;
	box-shadow: 0 0 5px #797879;
}
/* -- Show More End --*/
/* -- 2. header-area start -- */
.header-area {
	background: #fff;
	border-bottom: 2px solid #f4f4f4;
}
.marqueeText {
	color: #fff;
	margin-top: 5px;
}
.marqueeText .overlay {
	--gradient-color: rgba(41, 109, 193, 1), rgba(0, 0, 80, 0) !important;
}
.logoSection {
	display: flex;
	justify-content: center;
}
.logoSection a {
	z-index: 1;
	width: 175px;
}
.logoSection a img {
	max-width: 175px;
    height: 71px;
}
.header-bottom .logoSticky {
    display: none;
}
/* -- 2. header-area end -- */
.mainmenu > ul{
	display:flex;
	justify-content: flex-end;
}
.mainmenu ul li {
	position:relative;
}
.mainmenu ul li:first-child{margin-left:0px;}
.mainmenu ul li:last-child{margin-right:0px;}
.mainmenu ul li > a{
	text-transform: uppercase;
	font-weight: 500;
	padding: 22px 15px;
	color: #333;
	display: block;
	position: relative;
	text-decoration: none;
}
.mainmenu ul li a.active,
.mainmenu ul li a.active:hover,
.mainmenu ul li:hover > a.active {
	background: #ef512c !important;
	color: #fff;
}
.mainmenu ul li a.active:before,
.mainmenu ul li a.active:after {
	display: none;
}
.mainmenu ul li a:hover {
	background: #ff6f4e;
	color: #fff;
}
.mainmenu ul li:hover,
.mainmenu ul li:hover > a {
	background: #ff6f4e;
	color: #fff;
}
.mainmenu ul li > a:before,
.mainmenu ul li > a:after {
	position: absolute;
	left: 15px;
	height: 1px;
	width:0;
	background: #fff;
	content: "";
	bottom: 24px;
	transition:all .5s;
	-webkit-transition:all .5s;
	-moz-transition:all .5s;
}
.mainmenu ul li > a:after {
	bottom:22px;
	left:auto;
	right:15px;
}
.mainmenu ul li a.active:before,
.mainmenu ul li a.active:after,
.mainmenu ul li a:hover:before,
.mainmenu ul li a:hover:after,
.mainmenu ul li:hover > a:after,
.mainmenu ul li:hover > a:before {
	width: 45%;
}
.header-bottom.headerScrollSticky .logoSticky {
    display: block;
	margin-right: 15px;
}
.header-bottom.headerScrollSticky .logoSticky a,
.header-bottom.headerScrollSticky .logoSticky:hover,
.header-bottom.headerScrollSticky .logoSticky:hover > a {
	padding: 0;
	background: transparent !important; 
}
.header-bottom.headerScrollSticky .logoSticky a:before,
.header-bottom.headerScrollSticky .logoSticky a:after {
	display: none;
}
.header-bottom.headerScrollSticky .logoSticky img {
	height: 55px;
	width: 66px;
	margin-top: 6px;
}
.mainmenu ul li a i{
	margin-left:2px;
}
.mainmenu ul li > ul.submenu{
	position: absolute;
	left: 0;
	top: 100%;
	background: #fff;
	box-shadow: 0px 2px 3px rgba(0,0,0,.15);
	width: 240px;
	padding-left: 0;
	opacity: 0;
	visibility: hidden;
    transition: all 0.3s ease 0s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
	z-index: 999;
	border-top: 2px solid #296dc1;
	text-align: left;
}
.mainmenu ul li:hover > ul.submenu {
	opacity:1;
	visibility:visible;
}
.mainmenu ul li > ul.submenu.right{
	left:auto;
	right:0;
}
.mainmenu ul li > ul.submenu li{
	display:block;
	padding:0px 0px;
	margin:0px;
}
.mainmenu ul li > ul.submenu li > a{
	text-transform:capitalize;
	padding:7px 15px;
	font-size:13px;
}
.mainmenu ul li > ul.submenu li:hover > a{
	color: #fff;
	background: #ff6f4e;
}
.mainmenu ul li > ul.submenu li.active > a{
    color: #fff;
	background: #296dc1;
}
.mainmenu ul li > ul.submenu li.active > a::before,.mainmenu ul li > ul.submenu li.active > a::after,.mainmenu ul li > ul.submenu li:hover > a:before,.mainmenu ul li > ul.submenu li:hover > a::after{
    display: none;
}
.mainmenu ul li > ul.submenu li > ul {
    left: 100%;
	top:0px;
}
.mainmenu ul li > ul.megamenu{
	position:absolute;
	right:0;
	z-index:9999;
	opacity:0;
	visibility:hidden;
	transition:all .3s;
	-webkit-transition:all .3s;
	-moz-transition:all .3s;
	width:594px;
	box-shadow:0px 2px 3px rgba(0,0,0,.15);
	padding:10px ;
	background:#fff;
	border-top:2px solid #296dc1;
	text-align:left;
}
.mainmenu ul li:hover > ul.megamenu{
	opacity:1;
	visibility:visible;
}
.mainmenu ul li > ul.megamenu li{
	width:33.33%;
	float:left;
	display:block;
	margin:0px;
	padding:0px 15px;
}
.mainmenu ul li > ul.megamenu li a{
	text-transform:capitalize;
	padding:3px 0px;
	font-size:13px;
}
.mainmenu ul li > ul.megamenu li a.mega-title {
    text-transform: uppercase;
    font-weight: 500;
    color: #333;
    font-size: 15px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    position: relative;
}
.mainmenu ul li > ul.megamenu li a.mega-title::before,.mainmenu ul li > ul.megamenu li a.mega-title::after{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 1px;
    background:#296dc1;
    content: "";
}
.mainmenu ul li > ul.megamenu li a.mega-title::after{
    left: 10px;
    bottom: 2px;
}
.mainmenu ul li > ul.megamenu li:hover a.mega-title{
	background:transparent;
}
.mainmenu ul li > ul.megamenu li > ul li:hover a{
	color:#296dc1;
	background:transparent;
	padding-left:5px;
}
.mainmenu ul li > ul.megamenu > li.megatitle a{
	margin-bottom:5px;
	border-bottom:1px solid #f1f1f1;
	text-transform:uppercase;
}
.mainmenu ul li > ul.megamenu li > ul li{
	width:100%;
    padding:0px;
}
.mainmenu ul li > ul.megamenu li > ul li.active a{
	color:#296dc1;
	background:transparent;
}
.search-wrap ul li{
	width:100%;
	height:70px;
	background:#296dc1;
	position:relative;
	line-height:70px;
	transform:skew(15deg);
	overflow:hidden;
	position:relative;
	text-align:center;
	right:9px;
}
.search-wrap ul li a{
	color:#fff;
	display:block;
	transform:skew(-15deg);
	font-size:16px;
}
/*---------------------------- header-area end --------------------------*/

/*---------------------------- responsiv-area start --------------------------*/
.responsive-menu-wrap{
	position:relative;
}
.responsive-menu-wrap .slicknav_btn {
    background-color: transparent;
    border-radius: 0;
    display: block;
    float: right;
    margin: 0;
        padding: 28px 21px;
    text-decoration: none;
    text-shadow: none;
    vertical-align: middle;
    height: 70px;
    width: 100px;
    background: #296dc1;
    transform: skew(15deg);
    -moz-transform: skew(15deg);
    -webkit-transform: skew(15deg);
}
.slicknav_menu .slicknav_icon {
	-moz-transform: skew(-20deg);
	-webkit-transform: skew(-20deg);
	transform: skew(-20deg);
	margin: 2px 18px;
}
.responsive-menu-wrap .slicknav_menu .slicknav_menutxt {
	display: none;
}
.responsive-menu-wrap .slicknav_menu {
	background: transparent none repeat scroll 0 0;
	font-size: 15px;
	padding: 0;
	position: absolute;
	right: 9px;
	top: 0;
}
.responsive-menu-wrap .slicknav_menu .slicknav_icon-bar {
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  display: block;
  height: 2px;
  width: 25px;
  background: #fff;
}
.responsive-menu-wrap .slicknav_nav {
    background: #296dc1 none repeat scroll 0 0;
    border-radius: 0px;
    clear: both;
    color: #fff;
    font-size: 15px;
    margin: 0;
    padding: 5px 0;
    position: relative;
    top: 0;
    width: 720px;
    z-index: 99;
    right: -8px;
    z-index: 9999999;
    position: relative;
}
.slicknav_nav a {
	color: #f1f1f1;
	text-decoration: none;
	text-transform: capitalize;
}
.slicknav_nav a i{display:none}
.slicknav_nav a:hover {
	background: transparent none repeat scroll 0 0;
	border-radius: 0;
	text-shadow:0px 3px 5px rgba(0,0,0,.5);
	color:#fff;
}
.responsive-menu-wrap .slicknav_nav .slicknav_row:hover {
	background: transparent;
}
.responsive-menu-wrap .slicknav_nav .slicknav_arrow {
	font-size: 10px;
	margin: 5px;
}
.responsive-menu-wrap .slicknav_open .slicknav_icon span:first-child {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  position: relative;
  top: 5px;
}
.responsive-menu-wrap .slicknav_open .slicknav_icon span:last-child{
	transform:rotate(45deg);
	-webkit-transform:rotate(45deg);
	-moz-transform:rotate(45deg);
}
.responsive-menu-wrap .slicknav_open .slicknav_icon span:nth-child(2){display:none}
/*-- responsiv-area end --*/
/*-- slider-area start --*/
.container.slider_area {
	max-width: 100%;
	padding: 0;	
}
/* .container.slider_area .carousel-inner { */
/* .myCarousel .owl-stage-outer,
.myCarousel .owl-stage-outer .owl-stage,
.myCarousel .owl-stage-outer .owl-stage .owl-item,
.myCarousel, */
.myCarousel .owl-stage-outer .owl-stage .owl-item img {
	min-height: 525px;
}
.myCarousel .owl-dots {
	display: none;
}
.myCarousel .owl-nav {
	position: absolute;
	top: 47%;
	margin: 0 !important;
	width: 100%;
}
.myCarousel .owl-nav .owl-prev {
	float: left;
}
.myCarousel .owl-nav .owl-next {
	float: right;
}
.myCarousel .owl-nav .owl-prev:hover,
.myCarousel .owl-nav .owl-next:hover {
	box-shadow: none;
	background-color: transparent !important;
}
.myCarousel .owl-nav .owl-prev span,
.myCarousel .owl-nav .owl-next span {
	font-size: 40px;
	border: 1px solid transparent;
	color: #2f2f2f;
	line-height: 12px;
	height: 35px;
	width: 35px;
	display: block;
	padding: 7px 0;
	text-align: center;
	border-radius: 50%;
	box-shadow: 0px 0px 10px -2px #555;
	background-color: rgba(255,255,255,0.3);
}
.myCarousel .owl-nav .owl-prev span:hover,
.myCarousel .owl-nav .owl-next span:hover {
	color: #000;
	background-color: rgba(255,255,255,1);
}
/*-- slider-area end --*/
/*-- Header Start --*/
.header-top{
    padding: 15px 0px;
    border-bottom: 1px solid #77adef;
}
.header-top-left {
	width: calc(100% - 195px);
}
.header-top-right {
	width: 195px;
}
.header-top-left marquee {
    color: #fff;
    font-style: italic;
	margin: 0;
	padding: 5px 0;
	width: 100%;
}
.header-top-right ul li {
    display: inline-block;
    margin-left: 0;
}
.header-top-right ul li a {
    color: #fff;
	border: 1px solid #fff;
	width: 30px;
	height: 30px;
	display: inline-block;
	border-radius: 50%;
	padding: 4px;
	font-size: 12px;
	text-align: center;
	margin-right: 5px;
}
.header-top-right ul li:last-child a {
	margin-right: 0;
}
.header-top-right ul li a:hover {
    color: #ef512c;
}
.header-bottom .mainmenu > ul {
    justify-content: flex-start;
	margin: 0;
	padding: 0;
}
.header-middle {
    padding: 10px 0px;
    position: relative;
    z-index: 9;
	box-shadow: 0px 1px 1px rgba(0,0,0,.1);
}
.header-middle::before{
    content: "";
    background-color: #fff;
    width: 40%;
    height: 100%;
    position: absolute;
    left: -50px;
    top: 0;
    -webkit-transform: skewX(40deg);
    -moz-transform: skewX(40deg);
    -o-transform: skewX(40deg);
    -ms-transform: skewX(40deg);
    transform: skewX(40deg);
}
.header-middle-right {
    margin: 12px 0;
}
.header-middle-right ul{
    display: flex;
    justify-content: flex-end;
}
.header-middle-right ul li{
    margin-left: 30px;
    display: flex;
}
.header-middle-right ul li:first-child{
    margin-left: 0px;
}
.header-middle-right ul li .contact-icon i{
    font-size: 40px;
    margin-right: 15px;
    line-height: 40px;
    color: #fff;
}
.header-middle-right ul li .contact-info p{
    color: #fff;
    line-height: 20px;
	margin-bottom: 0;
}
.header-middle-right ul li .contact-info span{
    font-size: 12px;
    font-style: italic;
    color: #d2d2d2;
}
.header_social_icon {
	font-size: 0;
}
.header_social_icon ul {
	margin-bottom: 0;
	text-align: right;
	padding: 0;
}
.logo_desktop_hide,
.menu_desktop_hide {
	display: none;
}
/*-- Header End --*/
/*-- Fullstack Design Start --*/
.fullstackTop {
	margin-top: 35px;
}
.fullstack_dsign {
	margin-bottom: -60px;
}
.section-title h2 {
	margin-bottom: 15px;
	padding-bottom: 15px;
	position: relative;
	text-transform: capitalize;
	font-size: 32px;
	line-height: 38px;
}
.section-title P {
	margin-bottom: 60px;
}
.section-title h2:before,.section-title h2:after,.fanfact-wrap h2:before,.fanfact-wrap h2:after{
	position: absolute;
	left: 49%;
	bottom: 0;
	width: 60px;
	height: 1px;
	background: #296dc1;
	content: "";
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
}
.section-title h2:after,.fanfact-wrap h2:after{
	bottom: -2px;
	left: 51%;
}
.fullstack_section {
	text-align: center;
	box-shadow: 0px 0px 10px 0px #d7d7d7;
	height: 100%;
	padding: 15px;
	position: relative;
}
.fullstack_box_section {
	/* box-shadow: 0px 0px 10px 0px #d7d7d7;
	height: 100%;
	padding: 15px; */
}
.fullstack_section img {	
	font-size: 35px;
	color: #000;
	margin-bottom: 15px;
	/* border: 2px solid #296dc1; */
	border-radius: 50%;
	height: 80px;
	width: 80px;
	/* padding: 20px 5px; */
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	top: -40px;
}
.fullstack_section h6 {
	font-size: 18px;
	margin-bottom: 10px;
	font-weight: 600;
	min-height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 25px;
}
.fullstack_section p {
	font-size: 16px;
	margin-bottom: 0;
	min-height: 65px;
}
.fullstack_bottom {
	margin-bottom: 64px;
}
/*-- Fullstack Design End --*/
/*-- Our Solution Start --*/
.our_solution {
	margin-top: 50px;
}
.team-wrap{
	padding-bottom: 90px;
	position: relative;
	z-index: 1;
	overflow: hidden;
}
.projectsTop {
	margin-top: -15px;
}
.projects_top {
	margin-top: 15px;
}
.team-img{
	position: relative;
	z-index: 9;
	min-height: 345px;
}
.team-img:before{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:#000;
	content:"";
	opacity:0;
	z-index:9;
	 -webkit-transition: -webkit-transform 0.6s;
    -moz-transition: -moz-transform 0.6s;
    transition: transform 0.6s;
	transform:scaleY(0);
	-webkit-transform:scaleY(0);
	-moz-transform:scaleY(0);
	transform-origin:top;
	-webkit-transform-origin:top;
	-moz-transform-origin:top;
}
.team-wrap:hover .team-img:before{
	transform:scaleY(1);
	-webkit-transform:scaleY(1);
	-moz-transform:scaleY(1);
	opacity:.5;
}
.team-img img{
    -webkit-transition: -webkit-transform 0.6s;
    -moz-transition: -moz-transform 0.6s;
    transition: transform 0.6s;
	width: 100%;
	height: 345px;
}
.team-wrap:hover .team-img img{
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
}
.team-content{
	/* height: 90px; */
	height: 104px;
	width: 100%;
	bottom: 0;
	position: absolute;
	text-align: center;
	overflow: hidden;
	-webkit-transition: all 0.6s;
	-moz-transition: all 0.6s;
	transition: all 0.6s;
	z-index:9;
	backface-visibility:#296dc1;
}
.team-content:before{
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background:#296dc1;
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    transition: all 0.6s;
    left: 0;
	z-index:-9;
}
.team-wrap:hover .team-content:before{
	height: 100%;
	-webkit-transition: all 0.6s;
	-moz-transition: all 0.6s;
	transition: all 0.6s;
}
.team-wrap:hover .team-content{
	/* height: 175px; */
	height: auto;
}
.team-info {
	background:#296dc1;
	padding: 12px 20px 20px 20px;
}
.team-content h3 {
	text-transform:uppercase;
	margin-bottom:0px;
	color:#fff;
	font-size:15px;
}
.team-content p {
	color:#fff;
	font-style:italic;
	margin-bottom:10px;
	font-size:12px;
	font-weight:300;
	letter-spacing:1px;
}
.team-content ul {
	opacity: 0;
	-webkit-transform: translateY(100%);
	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	transform: translateY(100%);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.6s, opacity 0.4s;
	-moz-transition: -moz-transform 0.6s, opacity 0.4s;
	transition: transform 0.6s, opacity 0.4s;
}
.team-wrap:hover .team-content ul {
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
	-webkit-transition: -webkit-transform 0.6s, opacity 0.4s;
	-moz-transition: -moz-transform 0.6s, opacity 0.4s;
	transition: transform 0.6s, opacity 0.4s;
}
.team-content ul li {
    opacity: 0;
    -moz-transform: translateY(40px);
    -webkit-transform: translateY(40px);
    -o-transform: translateY(40px);
    -ms-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition: -webkit-transform 0.6s, opacity 0.1s;
    -moz-transition: -moz-transform 0.6s, opacity 0.1s;
    transition: transform 0.6s, opacity 0.1s;
	display: inline-block;
	margin-right: 5px;
}
.team-content ul li:last-child {
	margin-right: 0;
}
.team-wrap:hover .team-content ul li{
    opacity: 1;
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -o-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}
.team-wrap:hover .team-content ul li:nth-child(1){
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
}
.team-wrap:hover .team-content ul li:nth-child(2){
    -webkit-transition-delay: 200ms;
    transition-delay: 200ms;
}
.team-wrap:hover .team-content ul li:nth-child(3){
    -webkit-transition-delay: 300ms;
    transition-delay: 300ms;
}
.team-wrap:hover .team-content ul li:nth-child(4){
    -webkit-transition-delay: 400ms;
    transition-delay: 400ms;
}
.team-wrap:hover .team-content ul li:nth-child(5){
    -webkit-transition-delay: 500ms;
    transition-delay: 500ms;
}
.team-content ul li a {
	background: #fff;
	height: 27px;
	width: 27px;
	line-height: 25px;
	text-align: center;
	display: block;
}
.team-info ul li {
	color: #fff;
	font-weight: 600;
	letter-spacing: 0.5px;
}
/*-- Our Solution End --*/
/*-- Client-area Start --*/
.carousel_se_01_carousel .owl-dots {
	display: none;
}
.client_say {
	margin-top: 35px;
}
.service-wrap {
    background-color: transparent;
}
.client_say .owl-carousel .owl-stage {
    display: flex;
}
.owl-item .item,
.service-wrap,
.service-content {
    height: 100%;
}
.service-wrap h3 {
    font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
	overflow: hidden; 
	text-overflow: ellipsis; 
	white-space: nowrap;
}
.service-wrap p {
    font-size: 17px;
	margin: 5px 0 0 0;
	min-height: 22px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.service-wrap p i {
    font-size: 12px;
}
.service-wrap p i.fa-quote-left {
    margin-right: 5px;
	color: #296dc1;
}
.service-wrap p i.fa-quote-right {
    margin-left: 5px;
	color: #296dc1;
}
.service-content {
    padding: 30px 20px;
	min-height: 140px;
	height: auto;
}
.client_rating i {
	font-size: 12px;
	color: #ef512c;
	margin-right: 3px;
}
.client_rating i:last-child {
	margin-right: 0;
}
.service-wrap:hover,
.service-wrap:hover h3,
.service-wrap:hover p,
.service-wrap:hover i,
.service-wrap:hover i.fa-quote-left,
.service-wrap:hover i.fa-quote-right {
    background-color: #296dc1;
	color: #fff;
}
.client_say .owl-nav,
.client_say .owl-dots {
	display: none;
}
.client_say .section-title P {
    margin-bottom: 10px;
}
.clientPic {
	width: 150px;
}
.clientPic img {
	width: 126px;
	height: 126px;
	border: 1px solid #b5b5b5;
	border-radius: 50%;
	padding: 2px;
}
.clientText {
	width: calc(100% - 150px);
	padding-left: 0;
	align-self: center;
}
.clientBG {
	background-color: #f2f2f2;
	padding: 20px 15px;
	margin-top: 15px;
}
.ratingSection {
	margin-top: 5px;
}
.ratingSection span:nth-child(1) {
	position: relative;
	top: 1px;
}
.ratingSection span:nth-child(1) i {
	margin-right: 2px;
	color: #ef512c;
	font-size: 14px;
}
.ratingSection span:nth-child(1) i:last-child {
	margin-right: 0;
}
.ratingSection span:nth-child(2) {
	background-color: #ef512c;
	border: 1px solid #ef512c;
	border-radius: 5px;
	color: #fff;
	font-size: 12px;
	line-height: 18px;
	padding: 2px 5px;
	margin-left: 5px;
}
.ratingSection small {
	font-size: 15px;
	margin-left: 15px;
	font-weight: 600;
	position: relative;
	top: 2px;
}
.clientText h6 {
	font-size: 24px;
	line-height: 29px;
	font-weight: 600;
	color: #2d2d2d;
	margin-bottom: 0;
}
.clientText p {
	font-size: 17px;
	line-height: 22px;
	color: #b3b3b3;
	margin-top: 5px;
	margin-bottom: 0;
	font-style: italic;
}
.client_desc p {
	font-size: 16px;
	line-height: 21px;
	color: #555;
	margin-top: 10px;
}
.client_person {
	border-top: 2px dotted #555;
	padding-top: 15px;
	margin-top: 15px;
	text-align: right;
}
.client_person h6 {
	font-size: 18px;
	font-weight: 600;
	color: #000;
	margin-bottom: 2px;
	font-family: arial;
	text-transform: capitalize;
}
.client_person p {
	font-size: 15px;
	color: #555;
	margin-bottom: 0;
	font-family: arial;
}
/*-- Client-area End --*/
/*-- About Us Start --*/
.aboutUs {
	margin-top: 35px;
	/* background-color: rgba(0,0,0,0.1); */
	background-color: #fafafa;
	padding: 55px 0;
	text-align: center;
}
.abouutUsBtn .btn {
	background-color: #fff;
	border-color: #fff;
}
/* -- About Us End -- */
/*-- Known Work start --*/
.container.slider_known {
	max-width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	margin-top: 25px;
	margin-bottom: -25px;
}
.container.slider_known .carousel-inner {
	height: 75px;
}
.container.slider_known .owl-carousel {
	/* padding-left: 0;
	padding-right: 0; */
}
.container.slider_known .owl-carousel.owl-drag .owl-item {
    height: 75px;
}
.container.slider_known .owl-carousel.owl-drag .owl-item img {
    height: 80px;
    object-fit: contain;
}
.container.slider_known .owl-carousel .owl-dots {
    display: none;
}
/*-- Known Work end --*/
/* -- Contact Us Start --*/
.contactUsRight {
	background-color: #f2f2f2;
	padding: 20px;
	height: 100%;
}
.contactUsRight .ourOffice {
	font-size: 22px;
	margin-bottom: 25px;
	font-weight: 600;
}
.contactUsRight .ourOffice i {
	font-size: 25px;
	margin-right: 10px;
	position: relative;
	top: 2px;
	color: #ef512c;
}
.contactUsRight p {
	font-size: 17px;
	margin-bottom: 15px;
}
.contactUsRight p i {
	font-size: 17px;
	margin-right: 10px;
	color: #296dc1;
}
.contactUsRight p b {
	color: #333;
}
.contactUsRight p span {
	display: block;
	padding-left: 30px;
}
.contactLeft {
	width: calc(100% - 345px);
}
.contactRight {
	width: 345px;
}
.contactLeft .form-group {
	margin-bottom: 15px;
}
.contactLeft .form-group label {
	font-size: 15px;
	font-weight: 600;
	margin-bottom: 5px;
}
.contactLeft .form-group .form-control:focus,
.submitBtn .btn:focus {
	box-shadow: none;
}
.btnloader {
	border: 3px solid #f3f3f3;
	border-radius: 50%;
	border-top: 3px solid transparent;
	width: 22px !important;
	height: 22px !important;
	margin-right: auto !important;
	margin-left: auto;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}
@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.errorMesg {
	font-size: 12px;
	color: #fd0000 !important;
	margin-top: 2px;
	text-align: left;
}
/* -- Contact Us End --*/
/* -- About Us Start --*/
.aboutUsPage p {
	font-size: 16px;
	margin-bottom: 20px;
}
.aboutUsPage p:last-child {
	margin-bottom: 0;
}
/* -- About Us End --*/
/*-- footer-area start --*/
.footer-top {
	/* padding: 100px 0px; */
	padding: 55px 0;
	background:#296dc1;
	margin-top: 25px;
}
.footer-logo p {
    color: #fafafa;
    margin-bottom: 0;
	margin-top: 10px;
}
.quick-contact ul {
	padding-left: 0;
}
.quick-contact ul li {
    line-height: 30px;
    color: #f1f1f1;
    font-style: italic;
}
.quick-contact ul li i{
    margin-right: 5px;
}
.footer-widget ul {
	padding-left: 0;
	margin-bottom: 0;
}
.footer-widget h2 {
    font-size: 20px;
    color: #f1f1f1;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 15px;
}
.footer-widget h2::before,.footer-widget h2::after {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #f1f1f1;
    content: "";
    height: 1px ;
    width: 30px;
}
.footer-widget h2::after {
    left: 5px;
    bottom: -2px;
}
.footer-menu ul li {
    line-height: 30px;
}
.footer-menu ul li a {
    color: #fafafa;
    display: inline-block;
    padding-left: 15px;
    position: relative;
	font-size: 16px;
	text-decoration: none;
}
.footer-menu ul li a:hover {
    text-shadow: 0px 3px 5px rgba(0,0,0,.5);
}
.footer-menu ul li a:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "\f105";
    font-family: 'Font Awesome 5 Free';
	font-weight: 900;
}
.instagram-wrap ul {
    overflow: hidden;
}
.instagram-wrap ul li {
    padding:2px;
    width: 33.33%;
    float: left;
}
.instagram-wrap ul li a {
    display: block;
    position: relative;
}
.instagram-wrap ul li a:before{
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .2;
    content: "";
    transition: all .3s;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
}
.instagram-wrap ul li:hover a:before{
    opacity: .5;
}
/* .quick-contact input,.quick-contact textarea{
    height: 30px;
    background: transparent;
    border: 1px solid #f1f1f1;
    margin-bottom: 5px;
    width: 100%;
    color: #fff;
    padding-left: 10px;
}
.quick-contact textarea{
    height: 60px;
    margin: 0px;
}
.quick-contact button{
    padding: 5px 25px;
    border: 1px solid transparent;
}
.quick-contact ::-webkit-input-placeholder {
    color: #f1f1f1;
    font-size: 12px;
}
.quick-contact ::-moz-placeholder {
    color: #f1f1f1;
    font-size: 12px;
}
.quick-contact :-ms-input-placeholder {
    color: #f1f1f1;
    font-size: 12px;
} */
.footer-bottom{
    padding: 20px 0px;
    background: #333;
    color: #fff;
}
.quick-contact ul li.footer_social_icon {
	line-height: 22px;
	font-size: 0;
	margin-top: 8px;
}
.footer_social_icon a {
	color: #fff;
	border: 1px solid #fff;
	width: 30px;
	height: 30px;
	display: inline-block;
	border-radius: 50%;
	padding: 4px;
	font-size: 12px;
	text-align: center;
	margin-right: 5px;
}
.footer_social_icon a:hover {
	color: #ef512c;
}
.footer_social_icon a i {
	margin: 0 !important;
}
.footer-top .footerOne {
	width: 420px;
}
.footer-top .footerTwo {
	width: 135px;
	margin-left: auto;
}
.footer-top .footerThree {
	width: 215px;
	margin: 0 auto;
}
.footer-top .footerFour {
	width: 285px;
}
.footerFixed {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 10;
}
.newDeal input {
	border-radius: 0 !important;
	background-color: transparent !important;
	color: #fff !important;
	border-color: #fff !important;
}
.newDeal input:hover,
.newDeal input:focus {
	background-color: transparent !important;
	color: #fff !important;
	border-color: #fff !important;
	box-shadow: none !important;
}
.newDeal button {
	border-radius: 0 !important;
	background-color: #ef512c !important;
	color: #fff !important;
	border-color: #fff !important;
}
.newDeal button:hover,
.newDeal button:focus {
	background-color: transparent !important;
	color: #ef512c !important;
	border-color: #fff !important;
	box-shadow: none !important;
}
.newDeal input::-webkit-input-placeholder {
  color: #fff;
}
.newDeal input:-ms-input-placeholder {
  color: #fff;
}
.newDeal input::placeholder {
  color: #fff;
}
/*-- footer-area end --*/
/* -- All Container Start --*/
.fullstackTop .container,
.our_solution .container,
.client_say .container {
	max-width: 1140px;
}
.footer-top .container {
	max-width: 1340px;
}
/* -- All Container End --*/
/* -- Project Details Page Start --*/
.projectsLeft {
	width: 325px;
}
.projectsRight {
	width: calc(100% - 325px);
}
.projectsRight h6 {
	font-size: 32px;
	color: #296dc1;
	margin-bottom: 20px;
}
.projectsRight p {
	font-size: 16px;
	margin-bottom: 15px;
	color: #444;
}
.projectsRight p:last-child {
	margin-bottom: 0;
}
.projectsRight p b {
	font-size: 16px;
	font-weight: 600;
	margin-right: 5px;
}
.projectsRight p span {
	margin-right: 15px;
}
.projectsRight p span:last-child {
	margin-right: 0;
}
/* -- Project Details Page End --*/
/* -- Scroll Top Start --*/
.sc-dkPtRN {
	color: #fff;
}
.sc-dkPtRN svg {
	background-color: #ef512c;
	border-radius: 50%;
	cursor: pointer;
}
/* -- Scroll Top End --*/
/*-- Responsive Start --*/
@media ( max-width: 1400px ) {
	.header-area .container {
		max-width: 1320px !important;
	}
}
@media ( max-width: 1340px ) {	
	.footer-top .container {
		max-width: 100%;
	}
}
@media ( max-width: 1320px ) {
	.header-area .container {
		max-width: 100% !important;
	}
}
@media (max-width: 1175px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 505px;
		max-height: 505px;
	}
	.myCarousel .owl-nav {
		top: 46%;
	}
}
@media (max-width: 1155px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 495px;
		max-height: 495px;
	}
	.myCarousel .owl-nav {
		top: 45%;
	}
}
@media (max-width: 1140px) {
	.fullstackTop .container,
	.our_solution .container,
	.footer-top .container {
		max-width: 100%;
	}
}
@media (max-width: 1135px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 485px;
		max-height: 485px;
	}
}
@media (max-width: 1115px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 475px;
		max-height: 475px;
	}
}
@media (max-width: 1095px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 465px;
		max-height: 465px;
	}
}
@media (max-width: 1075px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 455px;
		max-height: 455px;
	}
}
@media (max-width: 1072px) {
	.footer-top .container {
		width: 500px;
	}
	.footer-top .footerOne {
		width: 365px;
	}
	.footer-top .footerThree {
		margin-top: 25px;
		margin-right: 0;
		margin-left: 0;
	}
	.footer-top .footerFour {
		margin-top: 25px;
	}
}
@media (max-width: 1055px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 445px;
		max-height: 445px;
	}
}
@media (max-width: 1035px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 435px;
		max-height: 435px;
	}
}
@media (max-width: 1015px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 425px;
		max-height: 425px;
	}
}
@media (max-width: 991px) {
	#menu-header,
	.header-bottom.headerScrollSticky .logoSticky {
		display: none;
	}
	.logo_desktop_hide {
		display: block;
		width: calc(100% - 55px) !important;
		min-width: calc(100% - 55px) !important;
		max-width: calc(100% - 55px) !important;
	}
	.logo_desktop_hide .logoSection {
		justify-content: flex-start;
		padding: 10px 0;
	}
	.menu_desktop_hide {
		display: block;
		width: 55px !important;
		min-width: 55px !important;
		max-width: 55px !important;
	}
	.menuSection {
		height: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.menuSection i {
		font-size: 35px;
		color: #296dc1;
		cursor: pointer;
		width: 30px;
	}
	.menuSection i.fa-times {
		color: #e81616;
	}
	.logoDesktop {
		display: none;
	}
	.mainmenu ul li > ul.submenu {
		width: 100%;
	}
	.menuPart {
		position: relative;
	}
	.mainmenu {
		width: 255px;
		margin-left: auto;
		position: absolute;
		z-index: 10;
		background: #ddd;
		right: 12px;
		top: 0;
		box-shadow: 0px 1px 5px -1px #7e7f7f;
	}
	.mainmenu ul {
		display: block;
	}
	.mainmenu ul li:hover,
	.mainmenu ul li.active {
		background: #ef512c;
	}
	.mainmenu ul li a:before,
	.mainmenu ul li a:after {
		display: none;
	}
	.mainmenu ul li a {
		padding: 15px;
	}
    .about-img {
        display: none;
    }
    .featured-wrap ul li .featured-content .featured-img {
        display: none;
    }
    .featured-wrap ul li .featured-content .featured-info {
        width: 100%;
    }
    .faq-form.form-style {
        margin-top: 50px;
    }
    .form-style span {
        font-size: 14px;
        line-height: 24px;
    }
    .client-info p {
        font-size: 13px;
    }
    .newsletter-form form {
        margin-bottom: 0;
    }
    .service-wrap,.portfolio-wrap,.team-wrap,.blog-wrap {
        margin-bottom: 30px;
    }
    .header-middle::before {
    	display: none;
    }
    .header-middle-right ul {
    	justify-content: center;
		padding-left: 0;
		margin-bottom: 0;
    }
    .slider-items {
    	height: 400px;
    }
    .about-wrap {
    	height: 420px !important;
    }
    .team-area{
        padding: 100px 0px 70px;
    }
	.pb-140{padding-bottom:100px;}
    .home2-service-area {
    	padding-top: 0;
    }
    .team-wrap2{
        margin-bottom: 30px;
    }
    .team-area2,.pricing-table-area{
        padding: 0px 0px 70px;
    }
    .single-service-wrap {
        margin-bottom: 30px;
    }
    .quote-wrap a {
        padding: 8px 20px;
    }
    .portfolio-area3 .portfolio{
        width: 50%;
    }
    aside.right-sidebar {
        margin-top: 50px;
    }
    aside.left-sidebar {
        margin-bottom: 50px;
    }
    section.team-area.team-area2.team-area3.ptb-100 {
        padding: 100px 0px 70px;
    }
	.header-area .middleAll {
		min-width: 100%;
		max-width: 100%;
	}
	.header-middle-right {
		margin: 0;
	}
	.projects_top {
		width: 33.33%;
	}
}
@media (max-width: 995px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 415px;
		max-height: 415px;
	}
}
@media (max-width: 975px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 405px;
		max-height: 405px;
	}
}
@media (max-width: 955px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 395px;
		max-height: 395px;
	}
}
@media (max-width: 895px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 375px;
		max-height: 375px;
	}
	.our_solution_part {
		width: 50%;
	}
	.client_say .container {
		max-width: 100%;
	}
}
@media (max-width: 855px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 365px;
		max-height: 365px;
	}
	.our_solution .our_solution_section {
		width: 50%;
	}
}
@media (max-width: 835px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 355px;
		max-height: 355px;
	}
}
@media (max-width: 805px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 345px;
		max-height: 345px;
	}
}
@media (max-width: 795px) {
	.fullstack_dsign .fullstack_bottom {
		width: 50%;
	}
}
@media (max-width: 775px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 335px;
		max-height: 335px;
	}
}
@media (max-width: 767px) {	
	.projects_top {
		width: 50%;
	}
}
@media (max-width: 755px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 325px;
		max-height: 325px;
	}
	.myCarousel .owl-nav {
		top: 43%;
	}
}
@media (max-width: 745px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 320px;
		max-height: 320px;
	}
}
@media (max-width: 735px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 315px;
		max-height: 315px;
	}
}
@media (max-width: 725px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 310px;
		max-height: 310px;
	}
}
@media (max-width: 715px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 305px;
		max-height: 305px;
	}
}
@media (max-width: 705px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 300px;
		max-height: 300px;
	}
}
@media (max-width: 695px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 295px;
		max-height: 295px;
	}
}
@media (max-width: 685px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 290px;
		max-height: 290px;
	}
}
@media (max-width: 675px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 285px;
		max-height: 285px;
	}
	.clientPic {
		width: 100%;
		text-align: center;
	}
	.clientText {
		width: 100%;
		padding-left: 12px;
		margin-top: 10px;
	}
	.contactLeft,
	.contactRight {
		width: 100%;
	}
	.contactRight {
		margin-top: 15px;
	}
	.contactUsRight .ourOffice {
		display: block;
	}
	.contactUsRight p {
		display: inline-block;
		margin-right: 15px;
		margin-bottom: 0;
	}
	.contactUsRight p:nth-child(3),
	.contactUsRight p:nth-child(4) {
		position: relative;
		top: -8px;
	}
}
@media (max-width: 665px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 280px;
		max-height: 280px;
	}
}
@media (max-width: 655px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 275px;
		max-height: 275px;
	}
	.header-top-left {
		width: 100% !important;
		min-width: 100% !important;
		max-width: 100% !important;
		padding-right: 12px !important;
		text-align: center;
	}
	.header-top-right {
		width: 100% !important;
		min-width: 100% !important;
		max-width: 100% !important;
		margin-top: 10px !important;
	}
	.header_social_icon ul {
		text-align: center;
	}
	.projectsLeft {
		width: 100%;
	}
	.projectsRight {
		width: 100%;
		margin-top: 15px;
	}
	.marqueeText {
		margin-top: 0;
	}
}
@media (max-width: 645px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 270px;
		max-height: 270px;
	}
}
@media (max-width: 635px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 265px;
		max-height: 265px;
	}
	.header-middle-right ul {
		display: block;
		width: 235px;
		margin-left: auto;
		margin-right: auto;
	}
	.header-middle-right ul li {
		margin-left: 0;
		margin-bottom: 10px;
	}
	.header-middle-right ul li:last-child {
		margin-bottom: 0;
	}
}
@media (max-width: 625px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 260px;
		max-height: 260px;
	}
	.myCarousel .owl-nav {
		top: 42%;
	}
	.contactUsRight p {
		display: block;
		margin-right: 0;
		margin-bottom: 15px;
	}
	.contactUsRight p:nth-child(3),
	.contactUsRight p:nth-child(4) {
		top: 0;
	}
}
@media (max-width: 585px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 250px;
		max-height: 250px;
	}
	.fullstackTop {
		margin-top: 25px;
	}
	.section-title h2,
	.aboutUs h3 {
		font-size: 28px;
	}
	.aboutUs {
		padding: 35px 0;
	}
}
@media (max-width: 565px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 245px;
		max-height: 245px;
	}
}
@media (max-width: 555px) {	
	.projects_top {
		width: 100%;
	}
	.projects_top a {
		width: 295px;
		display: block;
		margin: 0 auto;
	}
}
@media (max-width: 545px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 240px;
		max-height: 240px;
	}
}
@media (max-width: 535px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 235px;
		max-height: 235px;
	}
	.myCarousel .owl-nav {
		top: 41%;
	}
}
@media (max-width: 525px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 385px;
		max-height: 385px;
	}
	.myCarousel .owl-nav {
		top: 44%;
	}
	.footer-top {
		padding: 35px 0 35px;
	}
	.footer-widget.quick-contact {
		margin-bottom: 0;
	}
	.footer-top .container {
		max-width: 318px;
		width: 318px;
	}
	.footer-top .footerOne {
		width: 100%;
	}
	.footer-top .footerTwo {
		width: 100%;
		margin-top: 25px;
	}
	.footer-top .footerThree {
		width: 100%;
	}
	.footerTwo .col-12 {
		width: 50%;
	}
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 230px;
		max-height: 230px;
	}
	.fullstack_dsign .fullstack_bottom {
		width: 100%;
	}
	.our_solution .our_solution_section {
		width: 100%;
	}
}
@media (max-width: 515px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 225px;
		max-height: 225px;
	}
}
@media (max-width: 505px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 220px;
		max-height: 220px;
	}
	.our_solution_part {
		width: 100%;
	}
}
@media (max-width: 495px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 215px;
		max-height: 215px;
	}
}
@media (max-width: 485px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 210px;
		max-height: 210px;
	}
	.myCarousel .owl-nav {
		top: 40%;
	}
	.section-title h2, .aboutUs h3 {
		font-size: 25px;
	}
}
@media (max-width: 475px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 205px;
		max-height: 205px;
	}
	.clientText h6 {
		font-size: 20px;
	}
	.ratingSection span:nth-child(1) i {
		font-size: 13px;
	}
	.ratingSection span:nth-child(2) {
		font-size: 11px;
	}
	.ratingSection small {
		font-size: 14px;
		top: 1px;
	}
	.clientText p {
		font-size: 15px;
	}
	.client_desc p {
		font-size: 15px;
		line-height: 20px;
	}
	.client_person h6 {
		font-size: 17px;
	}
	.client_person p {
		font-size: 14px;
	}
}
@media (max-width: 465px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 200px;
		max-height: 200px;
	}
	.marqueeText .overlay {
		--gradient-width: 100px !important;
	}
}
@media (max-width: 455px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 195px;
		max-height: 195px;
	}
}
@media (max-width: 445px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 190px;
		max-height: 190px;
	}
}
@media (max-width: 435px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 185px;
		max-height: 185px;
	}
	.myCarousel .owl-nav {
		top: 39%;
	}
}
@media (max-width: 425px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 180px;
		max-height: 180px;
	}
}
@media (max-width: 415px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 175px;
		max-height: 175px;
	}
}
@media (max-width: 405px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 170px;
		max-height: 170px;
	}
	.myCarousel .owl-nav {
		top: 38%;
	}
}
@media (max-width: 395px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 170px;
		max-height: 170px;
	}
}
@media (max-width: 385px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 165px;
		max-height: 165px;
	}
	.myCarousel .owl-nav {
		top: 37%;
	}
}
@media (max-width: 375px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 160px;
		max-height: 160px;
	}
	.marqueeText .overlay {
		--gradient-width: 50px !important;
	}
}
@media (max-width: 365px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 155px;
		max-height: 155px;
	}
}
@media (max-width: 355px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 150px;
		max-height: 150px;
	}
	.myCarousel .owl-nav {
		top: 36%;
	}
}
@media (max-width: 354px) {
	.container.slider_known .owl-carousel.owl-drag .owl-item img {
		height: 75px;
	}
}
@media (max-width: 345px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 145px;
		max-height: 145px;
	}
	.myCarousel .owl-nav {
		top: 35%;
	}
}
@media (max-width: 335px) {
	.myCarousel .owl-stage-outer .owl-stage .owl-item img {
		min-height: 140px;
		max-height: 140px;
	}
	.myCarousel .owl-nav {
		top: 34%;
	}
}
@media (max-width: 330px) {
	.footer-bottom .col-12 {
		font-size: 15px;
	}
}
/*-- Responsive End --*/